<template>
  <div class="estate f-container">
    <div class="head x3">
      <div>
        <el-input
          class="w320 mr10 f-search-bar"
          placeholder="楼盘名称"
          @keyup.enter.native="search"
          v-model="queryData.keyword"
        >
          <el-button slot="append" @click="search">搜索</el-button>
        </el-input>
        <area-cascader
          @setQueryData="setQueryData"
          class="w160 mr10"
        ></area-cascader>
        <el-select
          class="w160 mr10"
          @change="search"
          clearable
          v-model="queryData.propertyType"
          placeholder="物业类型"
        >
          <el-option
            v-for="(value, key) in propertyTypesList"
            :label="value"
            :value="Number(key)"
            :key="key"
          ></el-option>
        </el-select>
        <el-select
          class="w160"
          @change="search"
          clearable
          v-model="queryData.enterpriseId"
          placeholder="所属企业"
        >
          <el-option
            v-for="item in enterpriseList"
            :label="item.name"
            :value="item.id"
            :key="item.id"
          ></el-option>
        </el-select>
      </div>
      <el-button type="primary" icon="el-icon-plus" @click="showAddDialog"
        >创建楼盘</el-button
      >
    </div>
    <el-table border class="body mt20" :data="list">
      <el-table-column label="楼盘信息">
        <template v-slot="{ row }">
          <div class="base-info">
            <el-image class="cover" :src="row.imageUrl"></el-image>
            <div class="name-wrap y3">
              <p>{{ row.estateRealName }}</p>
              <p class="sub color9">
                {{ row.inUser }}，{{ row.enterpriseName }}，{{ row.inDate }}
              </p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="物业类型/区域商圈">
        <template v-slot="{ row }">
          <div class="title">{{ row.propertyTypeStrList.join("、") }}</div>
          <p class="sub color9">
            {{ link([row.regionName, row.areaName], "-") }}
          </p>
        </template>
      </el-table-column>
      <el-table-column label="上架平台" width="150">
        <template v-slot="{ row }">
          <el-button
            type="danger"
            plain
            round
            size="mini"
            v-if="row.shelvesStatus == 2"
          >
            <i class="el-icon-warning"></i> 已下架
          </el-button>
          <div class="platform-list" v-if="row.shelvesStatus == 1">
            <el-tooltip
              class="item"
              effect="dark"
              v-for="item in row.platformList"
              :key="item.platformId"
              :content="item.name"
              placement="bottom"
            >
              <el-image :src="item.logo">
                <div slot="error" style="text-align:center">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="160">
        <template v-slot="{ row }">
          <el-button type="text" @click="toEstateWorkbench(row)"
            >打开管理后台 <i class="el-icon-copy-document"></i
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <f-pagination
      :page-num.sync="queryData.pageNum"
      :page-size.sync="queryData.pageSize"
      :total="total"
      @change="getEstatePage"
    ></f-pagination>
    <edit-estate
      :visible.sync="toggleFormDialog"
      @refresh="getEstatePage"
    ></edit-estate>
  </div>
</template>

<script>
import { link } from "@/utils/index";
import {
  getEstatePage,
  getSystemCharacteris,
  enterpriseInfo
} from "@/api/estate";
import { enumeration } from "@/config";
import editEstate from "./_components/edit";
import areaCascader from "@/views/estate/_components/areaCascader";
import { encrypt } from "@/utils/crypto";

export default {
  components: {
    editEstate,
    areaCascader
  },
  data() {
    return {
      enterpriseList: [], // 所属企业
      list: [], //table数据
      toggleFormDialog: false,
      total: 0, //楼盘总数
      queryData: {
        //查询字段
        enterpriseId: "", //企业id
        keyword: "",
        companyCode: "",
        areaId: "",
        propertyType: "",
        propertyTypesList: [],
        pageNum: 1,
        pageSize: 10
      },
      propertyTypesList: enumeration.propertyTypesList //物业类型
    };
  },
  created() {
    this.getEstatePage();
    this.getSystemCharacteris();
    this.getEnterpriseInfo();
  },
  methods: {
    link,
    search() {
      this.queryData.pageNum = 1;
      this.getEstatePage();
    },
    async getSystemCharacteris() {
      //获取楼盘特色列表
      const res = await getSystemCharacteris();
      if (res) {
        this.characteristicsSystemList = res;
      }
    },
    async getEnterpriseInfo() {
      //获取所属企业
      const res = await enterpriseInfo();
      if (res) {
        this.enterpriseList = res;
      }
    },
    setQueryData(val) {
      // 城市-区域-商圈选择
      this.queryData = { ...this.queryData, ...val };
      this.getEstatePage(1);
    },
    async getEstatePage() {
      //分页查询楼盘列表
      this.queryData.propertyTypesList = this.queryData.propertyType
        ? [this.queryData.propertyType]
        : [];
      const res = await getEstatePage(this.queryData);
      if (res) {
        this.list = res.list;
        this.total = res.total;
      }
    },
    showAddDialog() {
      //点击创建按钮
      this.toggleFormDialog = true;
    },
    toEstateWorkbench(row) {
      //跳转到工作台
      this.$router.push({
        path: `/estate/dictionary/baseInfo`,
        query: {
          estateId: encrypt(row.estateId) // 参数加密
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.mt40 {
  margin-top: 40px;
}
.estate {
  .platform-list {
    .el-image {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      cursor: pointer;
      margin-right: 15px;
      background-color: #aaa;
    }
  }
  .base-info {
    display: flex;
    .cover {
      width: 50px;
      height: 50px;
      min-width: 50px;
      border-radius: 5px;
      overflow: hidden;
    }
    .name-wrap {
      padding-left: 20px;
      font-size: 16px;
      .sub {
        font-size: 14px;
      }
    }
  }
  .body {
    .status {
      float: left;
      height: 24px;
      padding: 0 10px;
      border-radius: 12px;
      cursor: pointer;
      span {
        padding-left: 5px;
      }
      &.status0 {
        color: $red;
        background: $redBg;
      }
      &.status1 {
        background: #eee;
      }
      &.status2 {
        color: $purple;
        background: $purpleBg;
      }
      &.status3 {
        color: $blue;
        background: $blueBg;
      }
    }
  }
}
</style>
